<template>
    <div
        class="list-item l-inline l-spread l-padded l-full-width"
        :class="{ clickable }"
        @click="$emit('list-item-click')"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'ListItem',
    props: {
        clickable: {
            type: Boolean,
            default: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.list-item {
    font-size: $fs-base;

    /* transition: background-color 0.1s; */

    &.clickable:hover {
        background-color: $color-gray-lighter;
    }
    &.clickable {
        cursor: pointer;
    }
}
</style>
