<template>
    <div>
        <List>
            <router-link to="/dashboard/">
                <ListItem>
                    <div class="l-inline l-gap-3 l-center-v">
                        <DashboardIcon width="24" height="24" />
                        <div>{{ $t('overview') }}</div>
                    </div>
                </ListItem>
            </router-link>

            <router-link to="/dashboard/assets">
                <ListItem>
                    <div class="l-inline l-gap-3 l-center-v">
                        <TripMultipleDestinationsIcon width="24" height="24" />
                        <div>{{ $t('shared.assets') }}</div>
                    </div>
                </ListItem>
            </router-link>

            <router-link v-if="isSuperuser" to="/message-stream">
                <ListItem>
                    <div class="l-inline l-gap-3 l-center-v">
                        <MonitorHeartRateIcon width="24" height="24" />
                        <div>Message Stream</div>
                    </div>
                </ListItem>
            </router-link>

            <router-link v-if="isStaff" to="/dashboard/settings">
                <ListItem>
                    <div class="l-inline l-gap-3 l-center-v">
                        <SettingsSliderIcon width="24" height="24" />
                        <div>{{ $t('settings') }}</div>
                    </div>
                </ListItem>
            </router-link>
        </List>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import DashboardIcon from '@/components/icons/DashboardIcon'
import List from '@/components/List'
import ListItem from '@/components/ListItem'
import SettingsSliderIcon from '@/components/icons/SettingsSliderIcon'
import TripMultipleDestinationsIcon from '@/components/icons/TripMultipleDestinationsIcon'
import MonitorHeartRateIcon from '@/components/icons/MonitorHeartRateIcon'

export default {
    name: 'DashboardMenu',
    components: {
        MonitorHeartRateIcon,
        DashboardIcon,
        List,
        ListItem,
        SettingsSliderIcon,
        TripMultipleDestinationsIcon,
    },
    computed: {
        ...mapGetters('authentication', ['isStaff', 'isSuperuser']),
    },
}
</script>

<i18n>
{
    "en": {
        "locations": "Locations",
        "overview": "Overview",
        "settings": "Settings"
    },
    "de": {
        "locations": "Standorte",
        "overview": "Übersicht",
        "settings": "Einstellungen"
    },
    "it": {
        "locations": "Locatione",
        "overview": "Sommario",
        "settings": "Configurazione"
    }
}
</i18n>

<style lang="scss" scoped>
.router-link-exact-active {
    .list-item {
        background-color: $color-gray-light;
    }
}
</style>
